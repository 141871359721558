import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [{
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import( /* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import( /* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/autologin',
      name: 'autologin',
      component: () => import( /* webpackChunkName: "autologin" */ '@/views/AutoLogin.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import( /* webpackChunkName: "about" */ '@/views/About.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/category',
      name: 'category',
      component: () => import( /* webpackChunkName: "category" */ '@/views/Category.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: () => import( /* webpackChunkName: "product-list" */ '@/views/ProductList.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/product/:id',
      name: 'product',
      component: () => import( /* webpackChunkName: "product" */ '@/views/ProductDetail.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import( /* webpackChunkName: "cart" */ '@/views/Cart.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/create-order',
      name: 'create-order',
      component: () => import( /* webpackChunkName: "create-order" */ '@/views/CreateOrder.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import( /* webpackChunkName: "order" */ '@/views/Order.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/order-detail',
      name: 'order-detail',
      component: () => import( /* webpackChunkName: "order-detail" */ '@/views/OrderDetail.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import( /* webpackChunkName: "user" */ '@/views/User.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import( /* webpackChunkName: "setting" */ '@/views/Setting.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/address',
      name: 'address',
      component: () => import( /* webpackChunkName: "address" */ '@/views/Address.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/viewPdf',
      name: 'viewPdf',
      component: () => import( /* webpackChunkName: "viewPdf" */ '@/views/ViewPdf.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/address-edit',
      name: 'address-edit',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/AddressEdit.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/Discount',
      name: 'discount',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Discount.vue'),
      meta: {
        index: 2
      },

    },
    {
      path: '/Apply',
      name: 'apply',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Apply.vue'),
      meta: {
        index: 2
      },

    },
    {
      path: '/Applyhistory',
      name: 'applyhistory',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Applyhistory.vue'),
      meta: {
        index: 2
      },

    },
    {
      path: '/Relationorder',
      name: 'relationorder',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Relationorder.vue'),
      meta: {
        index: 2
      },

    },
    {
      path: '/Applyreopen',
      name: 'applyreopen',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Applyreopen.vue'),
      meta: {
        index: 2
      },

    }, {
      path: '/Applyinput',
      name: 'applyinput',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Applyinput.vue'),
      meta: {
        index: 2
      },

    },
    {
      path: '/Information',
      name: 'information',
      component: () => import( /* webpackChunkName: "address-edit" */ '@/views/Information.vue'),

    },
    {
      path: '/News',
      name: 'news',
      component: () => import( /* webpackChunkName: "level2" */ '@/views/Information/news.vue'),
    },
    {
      path: '/Wenku',
      name: 'wenku',
      component: () => import( /* webpackChunkName: "level3" */ '@/views/Information/wenku.vue'),
    },
    {
      path: '/Newscontent',
      name: 'newscontent',
      component: () => import( /* webpackChunkName: "level3" */ '@/views/Information/newscontent.vue'),
    },
    {
      path: '/Cooperate',
      name: 'cooperate',
      component: () => import( /* webpackChunkName: "level3" */ '@/views/Information/cooperate.vue'),
    },
    {
      path: '/Oilprice',
      name: 'oilprice',
      component: () => import( /* webpackChunkName: "level3" */ '@/views/Information/oilprice.vue'),
    },
    {
      path: '/Transfer',
      name: 'transfer',
      component: () => import( /* webpackChunkName: "level3" */ '@/views/transfer.vue'),
    }
  ]
})

export default router